.table {
  width: 100%;

  thead {
    text-align: left;
  }

  tbody {
    tr {
      margin: 0;

      td {
        padding: 5px;

        border-radius: 3px;
      }
    }
    tr:nth-of-type(odd) {
      background-color: #eee;
    }
    tr:nth-of-type(even) {
      background-color: #ccc;
    }
  }
}