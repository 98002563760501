.form-row {
  display: flex;
  flex-direction: column;
}

.button {
  outline: none;
  padding: 1em;
}

.container {
  max-width: 250px;

  background-color: white;

  border-radius: 5px;
  padding:1em;

  display: flex;
  flex-direction: column;

  gap: 1em;
}

.title {
  font-size: 1.5em;
  text-align: center;
  padding: 0;
  margin: 0;
}