.layout {
  display: flex;
  flex-direction: column;

  gap: 1em;
}

.nav {
  display: flex;
  gap: 2em;
  align-items: flex-end;
}

.logo {
  max-width: 150px;
  max-height: 100px;
}