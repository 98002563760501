:root {
  --background: rgb(190, 229, 235);
  --dark-background: rgb(113, 180, 190);
  --standard-font-colour: rgb(0, 0, 0);
  --fonts: 'Roboto', sans-serif;
}

* {
  color: var(--standard-font-colour);
  font-family: var(--fonts);
}

html {
  background-color: var(--background);
}

html {
  display: grid;
  justify-content: center;
  align-items: center;
}

body {
  min-width: 800px;
  min-height: 450px;
  @media only screen and (max-width: 600px) {
    min-width: unset;
    width: 93vw;
    min-height: unset;
  }
  max-width: 1000px;
  max-height: 900px;

  background-color: var(--background);
}

@import './tables.scss';
@import './room.scss'